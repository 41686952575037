<template>
<div id="app" class="site grid-container container hfeed" style="height: auto !important">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbapks.com.ru/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbapks.com.ru/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbapks.com.ru/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a>Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbapks.com.ru/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="content" class="site-content" style="height: auto !important">
    <article id="article" class="center" style="height: auto !important">
      <section id="downloadpage" style="height: auto !important">
        <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
        <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />

        <div id="download" class="wp-block-buttons aligncenter" style="width: 100%; display: block; text-align: center" @click="downloadfrom('bigbtn')">
          <div class="wp-block-button">
            <div class="godxthemes-btn wp-block-button__linkvk ripple">
              <i class="material-icons">
                <svg version="1.1" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff">
                  <path d="m16 16-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zm5 2h6.775l-4.375-7h-5.4v-1h6l5 8v1 8h-25v-9l5-8h6v1h-5.4l-4.375 7h6.775v2c0 1.1046 0.89586 2 1.9974 2h5.0052c1.1031 0 1.9974-0.88773 1.9974-2v-2z" />
                </svg>
              </i>
              Download Now
            </div>
          </div>
        </div>
        <div class="wpx5">
          <p class="has-text-align-center" style="height: auto !important">
            If the download doesn't start in a few seconds,
            <span id="downloadapk" @click="download()">
              click here
            </span>
          </p>
        </div>
        <center>
          <div class="wp-block-buttons is-content-justification-center">
            <div class="wp-block-button">
              <a href="https://gbapks.com.ru/" class="wp-block-button__linkvk clickable">
                <i class="material-icons">
                  <svg class="icon" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M14.91 6.71c-.39-.39-1.02-.39-1.41 0L8.91 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L11.03 12l3.88-3.88c.38-.39.38-1.03 0-1.41z" />
                  </svg>
                </i>
                Back to The Post
              </a>
            </div>
          </div>
        </center>

        <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
        <a id="abc" :href="apk" target="_self" download />
        <div class="wpxads">
          <center>
            <p>
              By installing WhatsApp, you agree to our <a href="https://gbapks.com.ru/privacy/" class="jump-url">Privacy Policy</a>.
            </p>
            <p class="btns">
              <a href="https://gbwhatapks.com/gbwhatsapp-pro" target="_blank">GB WhatsApp Pro</a>
              <a href="https://gbwhatapks.com/fm-whatsapp" target="_blank">FM WhatsApp</a>
              <a href="https://gbwhatapks.com/yowhatsapp-download" target="_blank">YO WhatsApp</a>
            </p>
          </center>
        </div>

        <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
        <div class="guide">
          <h2>How to Download and Install GB WhatsApp for Android</h2>
          <ul>
            <li><strong>1. Click the "Download Now" Button: </strong>Tap the button at the top of this page to begin the download.</li>
            <li><strong>2. Enable "Install Unknown Sources": </strong>Go to your phone's settings, and enable this option to allow installation from external sources.</li>
            <li><strong>3. Install the App: </strong>Open the downloaded file, follow the installation prompts.</li>
            <li><strong>4. Verify Your Phone Number: </strong>Enter your phone number and complete the verification process to start using GB WhatsApp.</li>
          </ul>
        </div>
        <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
        <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
      </section>
    </article>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbapks.com.ru/privacy/">Privacy Policy</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbapks.com.ru/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>

</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/download.scss';

export default {
  name: 'Download',
  data() {
    return {
      pageName: 'download',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }

    // window.adsTag.renderReward(54983, (rewardedStatus) => {
    // 	if (rewardedStatus) {
    // 		// 执行激励广告展示完成后操作
    // 		console.log("广告已经展示");
    // 	} else {
    // 		// 执行激励广告展示未完成操作
    // 	}
    // });

    // window.adsTag.adBreak({
    //   zoneId: 54983,
    //   type: 'reward',
    //   rewardSkipSecond: 15,
    //   adBreakDone: (viewed) => {
    //     console.log('aaa');
    //     if (viewed) {
    //       // 广告成功展示
    //       console.log('1');
    //     } else {
    //       // 广告展示失败，或由用户中止
    //       console.log('2');
    //     }
    //   },
    // });
    // this.apk = process.env.VUE_APP_GBLINK
  },
  methods: {
    downloadfrom(reason) {
      this.download();
      this.$logEvent(`download_from_${reason}`, 'download');
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    download() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
    // download() {
    // 	console.log(this.apk);
    // 	this.$global.download(this.apk);
    // },
  },
};
</script>
